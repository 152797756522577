import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import Swiper, { Autoplay, EffectFade, Keyboard, Navigation } from 'swiper';
import Swal from 'sweetalert2';
import axios from 'axios';

// Set up Swiper
Swiper.use([Navigation, EffectFade, Autoplay, Keyboard]);

(() => {
  // Home hero
  const homeHeroSlider: HTMLDivElement = document.querySelector('.home-hero');
  if (homeHeroSlider) {
    const currentPage: HTMLSpanElement = homeHeroSlider.querySelector('.page-counter .current-page');

    new Swiper(homeHeroSlider, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: '.prev-btn',
        nextEl: '.next-btn',
      },
      autoplay: {
        delay: 7500,
      },
      on: {
        slideChange(swiper) {
          currentPage.innerText = (swiper.activeIndex + 1).toString();
        },
      },
    }).init();
  }

  // Auto-size textarea
  const textarea: HTMLTextAreaElement = document.querySelector('.contact textarea.form-control');
  if (textarea) {
    // Auto-grow message textarea
    textarea.addEventListener('input', function onInput() {
      this.parentElement.dataset.replicatedValue = this.value;
    });
  }

  // Contact submit
  Array.from(document.forms).forEach((form) => {
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      const data = new FormData(form);

      try {
        await axios.post<void>(form.action, data);

        Swal.fire('Mensagem enviada!', 'Em breve entraremos em contato.', 'success');
        form.reset();
      } catch (error) {
        Swal.fire('Erro', 'Não foi possível enviar a mensagem, tente novamente.', 'error');
      }
    });
  });

  // Project gallery modal
  const galleryEl = document.getElementById('gallery');
  if (galleryEl) {
    const slider = new Swiper(galleryEl.querySelector('.swiper-container') as HTMLDivElement, {
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    const shownBsModal = () => {
      slider.update();
      galleryEl.removeEventListener('shown.bs.modal', shownBsModal);
    };

    galleryEl.addEventListener('shown.bs.modal', shownBsModal);

    // Update slider on click thumb
    const thumbs: HTMLAnchorElement[] = Array.from(document.querySelectorAll('.image-grid a'));
    thumbs.forEach((thumb) => {
      thumb.addEventListener('click', () => {
        slider.slideTo(Number(thumb.dataset.imageIndex), 0); // speed=0 for immediate transition
      });
    });
  }
})();
